import React from 'react';
import LocationsMap from 'design-comuni-plone-theme/components/ItaliaTheme/View/Commons/LocationsMap';
import {
  richTextHasContent,
  RichText,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';

// import VenueWhere from 'design-comuni-plone-theme/components/ItaliaTheme/View/VenueView/VenueWhere';
// Versione di VenueWhere compatta
const VenueWhere = ({ content }) => {
  const locations = content.sede ?? [];
  return (
    <>
      <LocationsMap center={content} locations={locations} />
      <p>
        {richTextHasContent(content.notes) && (
          <div className="mt-5">
            <RichText content={content.notes} />
          </div>
        )}
        {[content.street, content.city].filter((v) => v !== null).join(' - ')}
        {(content.street || content.city) &&
          (content.zip_code || content.country) && <br />}

        {[content.zip_code, content.country?.title]
          .filter((v) => v !== null)
          .join(' - ')}
      </p>
    </>
  );
};

export default VenueWhere;
