/* CUSTOMIZATIONS:
    - Aggiunto Ruolo del responsabile tramite campo "assessore_riferimento_role" della UO oppure campo 
      "ruolo" del CT Persona
    - Rivisto l'ordine delle sezioni
    - Aggiunta la gestione dei luoghi come ambulatori
    - Rimosso direttore generale da Struttura (modifica solo su ausl-romagna)
*/

import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';
import { OfficeCard } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { Chip, ChipLabel } from 'design-react-kit/dist/design-react-kit';
import { useDispatch, useSelector } from 'react-redux';
import { contentFolderHasItems } from 'design-comuni-plone-theme/helpers';
import { searchContent, resetSearchContent } from '@plone/volto/actions';
import { LocationItem } from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const messages = defineMessages({
  struttura: {
    id: 'struttura',
    defaultMessage: 'Struttura',
  },
  legami_struttura_padre: {
    id: 'legami_struttura_padre',
    defaultMessage: 'Servizio o ufficio di appartenenza',
  },
  legami_strutture_figlie: {
    id: 'legami_strutture_figlie',
    defaultMessage: 'Servizi o uffici interni',
  },
  legami_altre_strutture: {
    id: 'legami_altre_strutture',
    defaultMessage: 'Servizi o uffici di riferimento',
  },
  responsabile: {
    id: 'responsabile',
    defaultMessage: 'Responsabile',
  },
  tipologia_organizzazione: {
    id: 'tipologia_organizzazione',
    defaultMessage: 'Tipologia organizzazione',
  },
  // assessore_riferimento: {
  //   id: 'assessore_riferimento',
  //   defaultMessage: 'Assessore di riferimento',
  // },
  altre_sedi: {
    id: 'altre_sedi',
    defaultMessage: 'Ambulatori',
  },
});

const Altriluoghi = ({ content, folder_name }) => {
  const intl = useIntl();
  const url = content
    ? `${flattenToAppURL(content['@id'])}/${folder_name}`
    : null;
  const key = folder_name + url;
  const searchResults = useSelector((state) => state.search.subrequests);
  const dispatch = useDispatch();

  const hasChildren = contentFolderHasItems(content, folder_name);

  useEffect(() => {
    if (folder_name && hasChildren) {
      dispatch(
        searchContent(
          url,
          {
            'path.depth': 1,
            sort_on: 'getObjPositionInParent',
            metadata_fields: '_all',
            fullobjects: 1,
            b_size: 100,
          },
          key,
        ),
      );

      return () => {
        dispatch(resetSearchContent(key));
      };
    }
    // eslint-disable-next-line
  }, [key]);

  const folder = content?.items.filter((item) => item.id === folder_name);
  const folder_title =
    folder?.[0]?.title || intl.formatMessage(messages.altre_sedi);
  const venues = searchResults?.[key]?.items || [];

  return venues.length > 0 ? (
    <article
      id="contatti"
      className="it-page-section anchor-offset mt-5"
      aria-labelledby="header-contatti"
    >
      <h5>{folder_title}</h5>
      <div className="card-wrapper card-teaser-wrapper card-teaser-wrapper-equal">
        {venues.map((item, _i) => (
          <LocationItem key={item['@id']} location={item} />
        ))}
      </div>
    </article>
  ) : (
    <></>
  );
};

const UOStructure = ({ content }) => {
  const intl = useIntl();

  return content?.legami_con_altre_strutture?.length > 0 ||
    content?.responsabile?.length > 0 ||
    content?.tipologia_organizzazione ||
    content?.uo_children?.length > 0 ||
    //content?.assessore_riferimento?.length > 0
    content?.uo_parent ? (
    <article
      id="struttura"
      className="it-page-section anchor-offset mt-5"
      aria-labelledby="header-struttura"
    >
      {/* Direttore generale in primo piano 
      {content.assessore_riferimento?.length > 0 && (
        <div className="mb-5 mt-3">
          {content.assessore_riferimento.map((item, _i) => (
            <div className="ruolo-persone-struttura">
              {content?.assessore_riferimento_role?.title ? (
                <h4>{content.assessore_riferimento_role.title}: </h4>
              ) : item?.ruolo ? (
                <h4>{item.ruolo}: </h4>
              ) : (
                <></>
              )}
              <Link
                to={flattenToAppURL(item['@id'])}
                key={item['@id']}
                title={item.title}
                className="text-decoration-none mr-2"
              >
                <Chip
                  color="primary"
                  disabled={false}
                  large={false}
                  simple
                  tag="div"
                >
                  <ChipLabel tag="span">{item.title}</ChipLabel>
                </Chip>
              </Link>
            </div>
          ))}
        </div>
      )} */}

      {/* Inizio sezione Struttura */}
      <h4 id="header-struttura" className="mb-3">
        {intl.formatMessage(messages.struttura)}
      </h4>

      {content.responsabile?.length > 0 && (
        <div className="mb-5 mt-3">
          <h5>{intl.formatMessage(messages.responsabile)}</h5>
          {content.responsabile.map((item, i) => (
            <Link
              to={flattenToAppURL(item['@id'])}
              key={item['@id']}
              title={item.title}
              className="text-decoration-none  mr-2"
            >
              <Chip
                color="primary"
                disabled={false}
                large={false}
                simple
                tag="div"
              >
                <ChipLabel tag="span">{item.title}</ChipLabel>
              </Chip>
            </Link>
          ))}
        </div>
      )}
      {/* {content.tipologia_organizzazione?.title && (
        <div className="mb-5 mt-3">
          <h5>{intl.formatMessage(messages.tipologia_organizzazione)}</h5>
          <p className="text-serif">{content.tipologia_organizzazione.title}</p>
        </div>
      )} */}

      {content.uo_parent && (
        <div className="mb-5 mt-3">
          <h5>{intl.formatMessage(messages.legami_struttura_padre)}</h5>
          <div className="card-wrapper card-teaser-wrapper card-teaser-wrapper-equal mb-3">
            <OfficeCard
              key={content.uo_parent['@id']}
              office={content.uo_parent}
              load_data={false}
              show_contacts={false}
            />
          </div>
        </div>
      )}

      {content.uo_children?.length > 0 && (
        <div className="mb-5 mt-3">
          <h5>{intl.formatMessage(messages.legami_strutture_figlie)}</h5>
          <div className="card-wrapper card-teaser-wrapper card-teaser-wrapper-equal mb-3">
            {content.uo_children.map((uo, i) => {
              return (
                <OfficeCard
                  key={uo['@id']}
                  office={uo}
                  load_data={false}
                  show_contacts={false}
                />
              );
            })}
          </div>
        </div>
      )}

      {content.legami_con_altre_strutture?.length > 0 && (
        <div className="mb-5 mt-3">
          <h5>{intl.formatMessage(messages.legami_altre_strutture)}</h5>
          <div className="card-wrapper card-teaser-wrapper card-teaser-wrapper-equal mb-3">
            {content.legami_con_altre_strutture.map((item, _i) => (
              <OfficeCard key={item['@id']} office={item} />
            ))}
          </div>
        </div>
      )}

      <Altriluoghi content={content} folder_name="luoghi" />
    </article>
  ) : (
    <></>
  );
};

export default UOStructure;
